<template>
  <section class="m-collect relative blue--bg">
    <div
      class="m-collect__wrap container d-flex flex-column"
      data-test="collect-block"
    >
      <h2 class="m-collect__title headline-2">
        Собрали {{ numberFormat(counts?.tariffsCount) }} тарифов и акций
      </h2>
      <div class="m-collect__img">
        <img
          loading="lazy"
          :src="
            imgUrl(
              disabledPortal
                ? '/media/uploads/blog/Macbook.png'
                : '/media/uploads/blog/Ipnone.png',
              disabledPortal ? 831 : 299,
              0,
            )
          "
          alt="device"
        >
      </div>
      <p class="m-collect__subtitle title-5 gray-dark--text">
        Мы нашли лучших интернет-провайдеров, выбрали для вас все самые
        популярные планы и супер-предложения со скидками до 100%. Оформите
        заявку в нашем маркетплейсе бесплатно в один клик
      </p>
      <span class="m-collect__color title-5">
        <img
          loading="lazy"
          src="@/assets/images/svg/percent.svg"
          alt="percent"
        >
        Предложения от более чем {{ providersCount }} провайдер{{
          counterFrom(providersCount)
        }}
        России</span>
      <div
        v-if="!houseUrl"
        class="m-collect__action d-flex align-center"
      >
        <template v-if="needGetCity">
          <ClientOnly>
            <a
              :href="`/${currentCity?.url}/`"
              class="m-collect__link title-4 d-btn d-btn--white-primary"
              style="background: transparent"
              @click.prevent="$router.push({ path: `/${currentCity?.url}/` })"
            >Смотреть для {{ getAddressText.cityOnlyPos }}</a>
          </ClientOnly>
        </template>
        <a
          v-else
          :href="`/${currentCity?.url}/`"
          class="m-collect__link title-4 d-btn d-btn--white-primary"
          style="background: transparent"
          @click.prevent="$router.push({ path: `/${currentCity?.url}/` })"
        >Смотреть для {{ getAddressText.cityOnlyPos }}</a>
        <button
          class="m-collect__btn title-4 primary--text"
          @click="showDialogCity"
        >
          Другой город
        </button>
      </div>
      <div
        v-else
        class="m-collect__action d-flex align-center"
      >
        <a
          :href="`/personal/address/${houseUrl}/`"
          class="m-collect__link title-4 d-btn d-btn--primary"
          @click.prevent="
            $router.push({ path: `/personal/address/${houseUrl}/` })
          "
        >Смотреть для моего дома</a>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { counterFrom, imgUrl, numberFormat } from '~/helpers'
import { useMainStore } from '~/store/main/main.store'
import { useCities } from '~/store/cites/cities.store'

const props = defineProps({
  counts: {},
})

const ctx = useNuxtApp()
const mainStore = useMainStore()
const disabledPortal = computed(() => mainStore.disabledPortal)
const cityStore = useCities()
const currentCity = computed(() => cityStore.getCity)
const getAddressText = computed(() => cityStore.getAddressText)
const houseUrl = computed(() => cityStore.getHouseUrl)
const needGetCity = computed(() => cityStore.getNeedGetCity)
const showDialogCity = () => {
  ctx.$event('cityDialog', {
    redirect: true,
    label: 'footer',
    redirectToMainPage: true,
  })
}
const providersCount = computed(() => {
  return props.counts.providersCount
})
</script>

<style scoped lang="scss">
.m-collect {
  padding: 64px 0;
  @media (max-width: getBreakpoint(desktop)) {
    padding: 40px 0;
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding: 40px 0 32px;
  }
  @media (max-width: getBreakpoint(mobile-md)) {
    padding: 40px 0 40px;
  }

  &__wrap {
    position: relative;
    min-height: 332px;
    padding-right: 640px;
    @media (max-width: getBreakpoint(desktop)) {
      min-height: 260px;
      padding-right: 200px;
    }
    @media (max-width: getBreakpoint(tablet)) {
      padding-right: 120px;
    }
  }

  &__title {
    margin-bottom: 12px;
  }

  &__img {
    @media (max-width: getBreakpoint(tablet)) {
      position: relative;
    }
    img {
      position: absolute;
      right: 20px;
      top: 0;
      width: 546px;
      @media (max-width: getBreakpoint(desktop)) {
        width: 166px;
        top: -16px;
      }
      @media (max-width: getBreakpoint(tablet)) {
        width: 177px;
        right: -170px;
      }
      @media (max-width: getBreakpoint(mobile-xl)) {
        top: -60px;
        width: 160px;
        right: -160px;
      }
      @media (max-width: getBreakpoint(mobile-lg)) {
        top: -30px;
        width: 150px;
        right: -150px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 8px;
    }
  }

  &__color {
    padding-left: 28px;
    position: relative;
    background: linear-gradient(
      89deg,
      #ee3c6b 5.03%,
      #a949e7 42.39%,
      #5f60f5 67.38%,
      #5b73f1 76.39%,
      #5bbbe3 99.87%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    img {
      position: absolute;
      left: 0;
      top: -2px;
        width: 24px;
        height: 24px;
      @media (max-width: getBreakpoint(tablet)) {
        top: -3px;
      }
    }
  }

  &__action {
    margin-top: 32px;
    @media (max-width: getBreakpoint(wide)) {
      flex-direction: column;
      margin-top: 24px;
      margin-right: auto;
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-right: unset;
    }
    @media (max-width: getBreakpoint(mobile-xl)) {
      margin-right: -100px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-right: -108px;
    }
  }

  &__link {
    //background: transparent;
    padding: 15px 40px;

    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
      max-width: 400px;
      margin-top: 22px;
      padding: 8px 12px;
      height: 44px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      height: 40px;
    }
  }
  &__btn {
    margin-left: 24px;
    transition: color 0.24s;
    &:hover {
      color: color(primary-dark);
    }
    @media (max-width: getBreakpoint(wide)) {
      margin-left: 0;
      margin-top: 16px;
    }
  }
}
</style>
